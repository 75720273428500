.main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    label{
        font-size: 16px;
    }


    .inputs{
        width: 45%;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        .prefisso{
            width: 40px;
            padding: 0 10px;
        }
    
        input{
            width: 100%;
            height: 38px;
            border-width: 0.5px;
            border-radius: 6px;
            padding: 0 10px;
        }
    }
    
}


@media screen and (max-width: 915px) {
    .main{

        label{
            // width: 80px;
        }

       .inputs{
        .prefisso{
            width: 30px;

        }
       }
        
    }

}