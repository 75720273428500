// .main{
//     height: 100vh;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;
//     padding: 40px;
//     background-color: #DDE6F1;
//     box-sizing: border-box;
//     gap: 50px;
  
//     ._header{
//       width: 100%;
//       display: flex;
//       justify-content: flex-start;
  
//       p{
//         font-size: 24px;
//         font-weight: 500;
//         margin: 0;
//       }
//     }
  
//     ._form {
//       background-color: white;
//       width: 500px;
//       padding: 20px;
//       border-radius: 10px;
//       border: 2px #0169C9 solid;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 40px 60px;

  
//       button{
//         align-self: flex-end;
//       }
//     }
//   }

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // background-color: #DDE6F1;
  background-color: #0069ca;
  box-sizing: border-box;

  .sidebar {
    box-sizing: border-box;
    width: 4%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .body {
    width: 96%;
    height: 100%;
    border-radius: 32px 0 0 32px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    padding: 5% 10%;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    ._form,
    ._response {
      background-color: white;
      width: 500px;
      padding: 40px 20px;
      border-radius: 10px;
      box-shadow: 0px 6px 16px 0px #1619661a;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .__abilita {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .___result {
          width: 60%;
          display: flex;
          justify-content: space-between;
        }
        button {
          width: 300px;
        }
      }

      .__notFound, .__found {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
