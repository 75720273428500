.mainButton {
  height: fit-content;
  width: 200px;
  padding: 10px 8px;
  background: linear-gradient(91.28deg, #0195ff 0%, #0169c9 100%);
  color: white;
  font-weight: 800;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:hover {
    filter: opacity(0.5);
  }

  &:disabled {
    background-color: #8f8f8f;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .ghostButton {
    height: fit-content;
    padding: 10px 0;
    color: #0069ca;
    background-color: transparent;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  &:hover {
    filter: opacity(0.5);
  }
}

@media screen and (max-width: 915px) {
  .secondaryButton,
  .mainButton {
  }
}
